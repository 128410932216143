// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GD8Z_CmzwwulJk6kRh0W{z-index:998;position:absolute;color:#f2f2f0;background-color:#434b4f;border-radius:0 0 7px 7px;box-shadow:0px 14px 14px rgba(0,0,0,.2);overflow:hidden;width:100vw;left:0;top:63px}.GD8Z_CmzwwulJk6kRh0W a{cursor:pointer;display:block}@media(min-width: 0){.GD8Z_CmzwwulJk6kRh0W a{padding:6px 12px}}@media(min-width: 400px){.GD8Z_CmzwwulJk6kRh0W a{padding:6px 12px}}@media(min-width: 768px){.GD8Z_CmzwwulJk6kRh0W a{padding:7px 14px}}.GD8Z_CmzwwulJk6kRh0W a:hover{background-color:#383e42}.notLoggedIn .GD8Z_CmzwwulJk6kRh0W{color:#434b4f;background-color:#fff}.notLoggedIn .GD8Z_CmzwwulJk6kRh0W a:hover{background-color:#f2f2f0}@media(max-width: 1024.02px){.GD8Z_CmzwwulJk6kRh0W{color:#f2f2f0;background-color:unset;box-shadow:none;overflow:hidden}.GD8Z_CmzwwulJk6kRh0W a{cursor:pointer}}@media(max-width: 1024.02px)and (min-width: 0){.GD8Z_CmzwwulJk6kRh0W a{padding:12px}}@media(max-width: 1024.02px)and (min-width: 400px){.GD8Z_CmzwwulJk6kRh0W a{padding:12px}}@media(max-width: 1024.02px)and (min-width: 768px){.GD8Z_CmzwwulJk6kRh0W a{padding:14px}}@media(max-width: 1024.02px){.GD8Z_CmzwwulJk6kRh0W a:hover{background-color:unset !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerSearchBoxResultsMobile": "GD8Z_CmzwwulJk6kRh0W"
};
export default ___CSS_LOADER_EXPORT___;
